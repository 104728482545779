<template>
  <div
    class="tab-pane fade"
    id="integration-setting"
    role="tabpanel"
    aria-labelledby="integration-setting-tab"
  >
    <WeCard>
      <!-- Card Title -->
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Entegrasyon Ayarları</h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <div class="text-center">
            <span class="btn btn-success" v-on:click="onSave"
              ><i class="fas fa-save"></i> Kaydet</span
            >
          </div>
          <!-- ./Submit -->
        </div>
      </div>
      <!-- ./Card Title -->
      <hr />

      <div class="row">
        <div class="col-12 col-md-6">
          <!-- Auto Exchange Rate -->
          <WeSettingCard
            name="auto-exchange-rate"
            title="Döviz Kur Entegrasyonu"
            description="Merkez bankasından kur farklarını otomatik günceller"
            v-model="data.auto_exchange_rate"
            class="mb-3"
          />
          <!-- Auto Exchange Rate -->

          <!-- Free Market -->
          <WeInput
            name="free-market"
            label="Serbest Piyasa"
            v-model="data.free_market"
            v-bind:disabled="data.auto_exchange_rate == 1"
          />
          <!-- Free Market -->
        </div>
        <div class="col-12 col-md-6">
          <!-- Entegra Product Import -->
          <WeSettingCard
            name="entegra_product"
            title="Entegra Ürün Yükleme"
            description="Entegrada bulunan ürünleri aktarma işlemi"
            v-model="data.entegra_product_import_state"
            class="mb-3"
          />
          <div class="row">
            <div class="col-12 col-md-8">
              <WeInput
                name="entegra_product_state"
                label="Ürün Listesi Linki"
                v-model="data.entegra_product_import_url"
                v-bind:disabled="true"
              />
            </div>
            <div class="col-12 col-md-4">
              <WeInput
                name="entegra_product_interval"
                label="Veri Çekme Aralığı (Saat)"
                v-model="data.entegra_product_import_cron_interval"
                v-bind:disabled="data.entegra_product_import_state == 0"
                type="number"
                v-bind:filterNumber="true"
                v-bind:minValue="1"
              />
            </div>
          </div>

          <!-- Entegra Product Import -->
        </div>
        <div class="col-12 col-md-6">
          <!-- Entegra Product Import -->
          <WeSettingCard
            name="entegra_order"
            title="Entagra Sipariş Gönderme"
            description="Entegra için anlık güncellenen sipariş xml oluşturma"
            v-model="data.entegraOrder_state"
            class="mb-3"
          />
          <div class="row">
            <div class="col-12 col-md-12">
              <label for="entegraOrder_state-698351" class="custom-label"
                >Listelenecek Gün Sayısı</label
              >

              <v-select
                label="name"
                v-bind:options="orderXmlTimeData"
                v-bind:reduce="(opt) => opt.id"
                v-bind:clearable="false"
                v-model="data.entegraOrder_subday"
                class="custom-v-select-sm"
                v-bind:disabled="data.entegraOrder_state == 0"
              >
              </v-select>
            </div>
            
            <div class="col-12 col-md-12 mt-2">
              <div class="row align-items-center">
                <div class="col-12 col-lg-10">
                  <WeInput
                    name="entegraOrder_state"
                    label="IP Adresi"
                    smallSize="true"
                    size="sm"
                    v-model="entegra_ip_address"
                    v-bind:disabled="data.entegraOrder_state == 0"
                  />
                </div>
                <div class="col-12 col-lg-2 mt-2">
                  <button
                    v-bind:disabled="data.entegraOrder_state == 0"
                    class="btn btn-primary btn-sm w-100"
                    v-on:click="addEntegraIp()"
                  >
                    Ekle
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-12 col-lg-10">
                  <label for="entegraOrder_state-698351" class="custom-label"
                    >IP Adresi Listesi</label
                  >
                  <v-select
                    label="ip"
                    v-bind:options="data.entegraOrder_api_address"
                    v-bind:reduce="(opt) => opt.id"
                    v-bind:clearable="false"
                    v-model="selected_entegra_ip_adress"
                    class="custom-v-select-sm"
                    v-bind:disabled="data.entegraOrder_state == 0"
                  >
                  </v-select>
                </div>
                <div class="col-12 col-lg-2 mt-3">
                  <button
                    v-bind:disabled="data.entegraOrder_state == 0"
                    class="btn btn-danger btn-sm w-100"
                    v-on:click="deleteEntegraIP()"
                  >
                    Kaldır
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 mt-2">
              <WeInput
                name="entegraOrder_url"
                label="Sipariş XML Linki"
                v-model="data.entegraOrder_url"
                v-bind:disabled="true"
              />
            </div>
          </div>

          <!-- Entegra Product Import -->
        </div>
      </div>
    </WeCard>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "General",
  data() {
    return {
      entegra_ip_address: null,
      selected_entegra_ip_adress: null,
      entegra_ip_options: [],
      orderXmlTimeData: [
        {
          id: "1",
          name: "Son 1 Gün",
        },
        {
          id: "3",
          name: "Son 3 Gün",
        },
        {
          id: "7",
          name: "Son 7 Gün",
        },
        {
          id: "15",
          name: "Son 15 Gün",
        },
        {
          id: "30",
          name: "Son 30 Gün",
        },
      ],
      data: {
        auto_exchange_rate:
          this.settings["site.auto_exchange_rate"] == "1" ? 1 : 0,
        free_market: this.settings["site.free_market"],
        entegra_product_import_state:
          this.settings["site.entegra_product_import_state"] == "1" ? 1 : 0,

        entegraOrder_state:
          this.settings["feed.entegraOrder_state"] == "1" ? 1 : 0,
        entegraOrder_url: this.settings["feed.entegraOrder_url"]
          ? `${this.settings["site.url"]}${this.settings["feed.entegraOrder_url"]}`
          : this.settings["site.url"],
        entegraOrder_api_address:
        this.settings["feed.entegraOrder_api_address"] ? JSON.parse(this.settings["feed.entegraOrder_api_address"]) : [],

        entegra_product_import_url:
          this.settings["site.entegra_product_import_url"] ?? "",
        entegra_product_import_cron_interval:
          this.settings["site.entegra_product_import_cron_interval"] ?? "1",
        entegraOrder_subday: this.settings["feed.entegraOrder_subday"] ?? "7",
      },
    };
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
     addEntegraIp(payload) {
      if (
        !this.data.entegraOrder_api_address.find(
          (A) => A == this.entegra_ip_address
        )
      ) {
        this.data.entegraOrder_api_address.push(
        {
          id: this.data.entegraOrder_api_address.length + 1,
          ip: this.entegra_ip_address
        }
        );
        this.entegra_ip_address = null
      }
    },
    deleteEntegraIP(payload) {
      let index = this.data.entegraOrder_api_address.findIndex(
        (A) => A.id == this.selected_entegra_ip_adress
      );
      if (index > -1) {
        this.data.entegraOrder_api_address.splice(index, 1);
        this.selected_entegra_ip_adress = null
      }
    },
    onSave() {
      this.settings["site.auto_exchange_rate"] = this.data.auto_exchange_rate;
      this.settings["site.entegra_product_import_state"] =
        this.data.entegra_product_import_state;
      this.settings["site.entegra_product_import_cron_interval"] =
        this.data.entegra_product_import_cron_interval;
      this.settings["feed.entegraOrder_state"] = this.data.entegraOrder_state;
      this.settings["feed.entegraOrder_api_address"] =
        JSON.stringify(this.data.entegraOrder_api_address)

      //this.settings["site.entegra_product_import_url"] = this.data.entegra_product_import_url;
      this.settings["feed.entegraOrder_subday"] =
        typeof this.data.entegraOrder_subday == "object"
          ? this.data.entegraOrder_subday.id
          : this.data.entegraOrder_subday;
      this.$parent.$emit("on-save", this.settings);
    },
  },
  computed: {
    ...mapState(["shared"]),
  },
  mounted() {
    this.data.entegraOrder_subday = this.orderXmlTimeData.filter(
      (A) => A.id == this.settings["feed.entegraOrder_subday"] ?? "7"
    );
  },
};
</script>
